<template>
    <div class="p-[24px] flex w-full flex-col gap-6 bg-white rounded-xl max-w-[350px]">
        <h3>Overdraw settings</h3>

        <div>
            <label for="support_overdraft">
                <input :disabled="!edit" id="support_overdraft" type="checkbox" v-model="updates.supports_over_draw" class="form-checkbox rounded">
                Support overdraft
            </label>
        </div>

        <div>
            <label for="max_overdraft">
                Maximum overdraft
            </label>
            <p v-if="!edit" class="border p-2 rounded text-lg font-bold">{{ formatNaira(Number(updates.max_over_draw_value)) }}</p>
            <input v-else type="text" :disabled="!updates.supports_over_draw" id="max_overdraft" 
                class="border py-3 px-2 rounded-lg w-full" placeholder="Maxumum overdraft"
                v-model.number="updates.max_over_draw_value" 
            />
        </div>

        <div>
            <div v-if="edit" class="flex gap-4 items-center">
                <button class="btn border border-black text-black" @click="cancelled">Cancel</button>
                <button :class="loading ? 'bg-gray-600' : 'bg-black' " class=" btn text-white"
                    @click="saveChanges" :disabled="loading"
                >
                    {{ loading ? 'Saving...' :  'Save' }}
                </button>
            </div>
            <button v-else class="btn bg-black text-white" @click="edit = !edit">Update</button>
        </div>
    </div>
</template>

<script setup>

import { ref, watchEffect } from 'vue'
import { usePatchCorporateWallet } from '@/composables/backend/wallet'
import {formatNaira} from '@/composables/utils'

const { loading, updatedCorporateWallet, patchCorporateWallet } = usePatchCorporateWallet()

const props = defineProps({
    walletItem: {
        type: Object,
        required: true,
    }
})

const emit = defineEmits(['corporateWalletUpdated'])
const updates = ref({
    supports_over_draw: false,
    max_over_draw_value: 0,
})
const edit = ref(false)


watchEffect(() => {
    updates.value.supports_over_draw = props.walletItem.supports_over_draw
    updates.value.max_over_draw_value = props.walletItem.max_over_draw_value
})

const cancelled = () => {
    edit.value = false
    updates.value.supports_over_draw = props.walletItem.supports_over_draw
    updates.value.max_over_draw_value = props.walletItem.max_over_draw_value
}

async function saveChanges() {
   const result = await patchCorporateWallet(props.walletItem.id, updates.value)
   emit('corporateWalletUpdated',result)
}
</script>

<style scoped>
.btn{
    width: fit-content;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px;
    white-space: nowrap;
}
</style>